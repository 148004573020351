import {
  AssetPlayback,
  VideoFile,
} from '@laminar-product/client-commons-core/core';
import axios from 'axios';
import shaka from 'shaka-player/dist/shaka-player.ui'; //Temporary: Incorrect export from shaka-package - https://gist.github.com/Security2431/2b28f17e11870bb4b0e347673e16d5ba
import { ShakaPlayerConfigurationOptions } from 'library/VideoPlayer/types';
import { Severity } from 'types/errors';
import {
  VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY,
  VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY,
} from './constants';
import { shakaDefaultRetryParameters } from './player';
import { captureError } from './captureError';

export const getDashConfig = (
  assetPlayback: AssetPlayback,
  videoFile: VideoFile
) => ({
  source: {
    src: assetPlayback?.url,
    type: videoFile.dash.type,
    keySystems: {
      'com.widevine.alpha': {
        supportedConfigurations: [
          {
            audioCapabilities: [
              {
                robustness: 'SW_SECURE_CRYPTO',
                contentType: 'audio/mp4;codecs="mp4a.40.2"',
              },
            ],
            videoCapabilities: [
              {
                robustness: 'SW_SECURE_CRYPTO',
                contentType: 'video/mp4;codecs="avc1.640028"',
              },
            ],
          },
        ],
        url: assetPlayback?.licenseUrl,
      },
    },
  },
  frames: assetPlayback?.frames,
  playlist: assetPlayback?.playlist,
  drmLicenseUrl: assetPlayback.licenseUrl,
  drmCertificateUrl: assetPlayback.certificateUrl,
});

export const getHlsConfig = (
  assetPlayback: AssetPlayback,
  videoFile: VideoFile
) => ({
  source: {
    src: assetPlayback.url,
    type: videoFile.hls.type,
    keySystems: {
      'com.apple.fps.1_0': {
        certificateUri: assetPlayback?.certificateUrl,
        getLicense: async function (
          emeOptions: any,
          contentId: string,
          keyMessage: any,
          callback: (error: Error | null, license?: ArrayBuffer) => void
        ) {
          try {
            const { data } = await axios.post(
              assetPlayback.licenseUrl,
              keyMessage,
              {
                responseType: 'arraybuffer',
                headers: {
                  'Content-type': 'application/octet-stream',
                },
              }
            );
            callback(null, data);
          } catch (e) {
            callback(e as any);
          }
        },
      },
    },
  },
  frames: assetPlayback.frames,
  playlist: assetPlayback.playlist,
  drmLicenseUrl: assetPlayback.licenseUrl,
  drmCertificateUrl: assetPlayback.certificateUrl,
});

export const getPlayerOptions = (
  restrictions: ShakaPlayerConfigurationOptions['restrictions'],
  drmLicenseUrl?: string,
  drmCertificateUrl?: string
): ShakaPlayerConfigurationOptions => ({
  abr: {
    restrictions,
    restrictToScreenSize: true,
  },
  restrictions,
  ...(drmLicenseUrl && {
    drm: {
      servers: {
        [VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY]: drmLicenseUrl,
        [VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY]: drmLicenseUrl,
      },
      advanced: {
        [VIDEO_PLAYER_WIDEVINE_DRM_SERVER_KEY]: {
          videoRobustness: 'SW_SECURE_CRYPTO',
          audioRobustness: 'SW_SECURE_CRYPTO',
        },
        [VIDEO_PLAYER_FAIRPLAY_DRM_SERVER_KEY]: {
          serverCertificateUri: drmCertificateUrl,
        },
      },
      retryParameters: shakaDefaultRetryParameters,
      initDataTransform(initData: any, initDataType: any, drmInfo: any) {
        if (initDataType !== 'skd') return initData;
        try {
          // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
          const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
          const contentId = skdUri.substring(skdUri.indexOf('skd://') + 6);
          const cert = drmInfo.serverCertificate;
          return shaka.util.FairPlayUtils.initDataTransform(
            initData,
            contentId,
            cert
          );
        } catch (error: any) {
          console.error('initDataTransform', error);
          captureError(error, 'FAIRPLAY_DRM', Severity.Error);
        }
      },
    },
  }),
  manifest: {
    retryParameters: { ...shakaDefaultRetryParameters, maxAttempts: 1 },
    raiseFatalErrorOnManifestUpdateRequestFailure: true,
  },
  streaming: {
    retryParameters: shakaDefaultRetryParameters,
  },
});
