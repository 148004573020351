import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentProfileUuid,
  setCurrentUserSubscription,
  setFirebaseMessagingToken,
  setLoginMethod,
  setUserDetails,
  setUserProfiles,
  setUserVerified,
} from 'store/user/actions';
import { useHistory } from 'react-router-dom';
import { deleteNotificationToken } from 'actions/notifications';
import { useCallback, useEffect } from 'react';
import {
  DataType,
  Profile,
  User,
} from '@laminar-product/client-commons-core/core';
import { setAppData } from 'store/app/actions';
import {
  setSelectedPrice,
  setSubscriptionPlan,
} from 'store/registration/actions';
import {
  selectCurrentProfile,
  selectCurrentProfileUuid,
  selectIsAnonymous,
  selectNotificationToken,
  selectProfiles,
} from 'store/user/selectors';
import { ProfileContentRating, ProfileDetailsTabKeys } from 'types/profiles';
import { resetPages } from 'store/pages/actions';
import { getAuth } from 'firebase/auth';
import { resetCollections } from 'store/collections/actions';
import { selectChromecastCastElement } from 'store/chromecast/selectors';
import { resetAssets } from 'store/assets/actions';
import { t } from 'i18next';
import { DropdownOption } from 'types/dropdown';
import { ReactComponent as BearIcon } from 'assets/icons/bear.svg';
import _omit from 'lodash/omit';
import { resetWatchlist } from 'store/watchlist/actions';
import {
  LOCAL_STORAGE_CURRENT_PROFILE_UUID,
  MATURITY_KIDS_VALUE,
  SESSION_STORAGE_NOTIFICATION_KEY,
} from './constants';
import { getProfileDetailsRoute } from './routing';
import useChangeProfileLanguage from './useChangeProfileLanguage';
export const parseProfile = (profileString: string | null): Profile | null => {
  if (!profileString) return null;

  return JSON.parse(profileString);
};

export const useSignOut = () => {
  const messagingToken = useSelector(selectNotificationToken);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const clearStoreOnProfileChange = useClearStoreOnProfileChange();
  const castElement = useSelector(selectChromecastCastElement);

  return () => {
    getAuth()
      .signOut()
      .then(() => {
        localStorage.removeItem(LOCAL_STORAGE_CURRENT_PROFILE_UUID);
        dispatch(setCurrentProfileUuid(null));
        dispatch(setUserVerified(false));
        dispatch(setUserProfiles(null));
        dispatch(setSubscriptionPlan());
        dispatch(setCurrentUserSubscription());
        dispatch(setSelectedPrice());
        dispatch(setUserDetails());
        dispatch(setLoginMethod());
        clearStoreOnProfileChange();

        if (castElement) {
          castElement.disconnect();
        }

        if (messagingToken) {
          deleteNotificationToken(messagingToken);
          dispatch(setFirebaseMessagingToken(null));
          window.sessionStorage.removeItem(SESSION_STORAGE_NOTIFICATION_KEY);
        }
        push('/');
      });
  };
};

export const getCurrentProfileUuidFromLocal = () =>
  localStorage.getItem(LOCAL_STORAGE_CURRENT_PROFILE_UUID);

export const useGetCurrentProfile = (): Profile | undefined => {
  const currentProfile = useSelector(selectCurrentProfile);
  const profiles = useSelector(selectProfiles);
  const isAnonymous = useSelector(selectIsAnonymous);
  const setCurrentProfile = useSetCurrentProfile();
  const localProfileUuid = getCurrentProfileUuidFromLocal();

  useEffect(() => {
    if (localProfileUuid && !currentProfile?.uuid && !isAnonymous) {
      const profile = profiles?.find(
        (profile) => profile.uuid === localProfileUuid
      );

      if (profile) {
        setCurrentProfile(profile);
      }
    }
  }, [
    currentProfile,
    isAnonymous,
    localProfileUuid,
    profiles,
    setCurrentProfile,
  ]);

  return currentProfile;
};

const useClearStoreOnProfileChange = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(resetAssets());
    dispatch(setAppData(DataType.CONTINUE_WATCHING, '', []));
    dispatch(resetCollections());
    dispatch(resetPages());
    dispatch(resetWatchlist());
  }, [dispatch]);
};

export const useSetCurrentProfile = () => {
  const dispatch = useDispatch();
  const clearStoreOnProfileChange = useClearStoreOnProfileChange();
  const { changeProfileLanguage } = useChangeProfileLanguage();
  const { push } = useHistory();
  const currentProfile = useSelector(selectCurrentProfile);
  return useCallback(
    async (profile: Profile | null) => {
      //TODO: Trigger animation for profile change
      dispatch(setCurrentProfileUuid(profile?.uuid || ''));
      clearStoreOnProfileChange();
      localStorage.setItem(
        LOCAL_STORAGE_CURRENT_PROFILE_UUID,
        profile?.uuid || ''
      );

      await changeProfileLanguage(profile);

      //When changing current profile to another one all profile related data in store is cleared and user is navigated to home page
      if (currentProfile && currentProfile.uuid !== profile?.uuid) {
        push('/');
      }
    },
    [
      changeProfileLanguage,
      clearStoreOnProfileChange,
      currentProfile,
      dispatch,
      push,
    ]
  );
};

export const useUpdateProfilesState = () => {
  const profiles = useSelector(selectProfiles);
  const dispatch = useDispatch();

  return (profile: Profile) => {
    //BE issue - api is returning _id property when creating profile in response but when resending it in edit patch api returns 500
    const profileToSave = _omit(profile, '_id');

    if (profiles) {
      const isNewProfile = !profiles.find((p) => p.uuid === profileToSave.uuid);

      if (isNewProfile) {
        return dispatch(setUserProfiles([...profiles, profileToSave]));
      }

      const newProfiles = profiles.map((profile) =>
        profile.uuid === profileToSave.uuid ? profileToSave : profile
      );

      dispatch(setUserProfiles(newProfiles));
      return;
    }

    dispatch(setUserProfiles([profileToSave]));
  };
};

export enum ProfileAvatarSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
}

export const checkForProfileLimit = (profiles?: Profile[], userData?: User) =>
  profiles && userData?.plan && profiles.length < userData.plan.profileLimit;

export const useNavigateToProfileDetails = () => {
  const currentProfileUuid = useSelector(selectCurrentProfileUuid);
  const setCurrentProfile = useSetCurrentProfile();
  const history = useHistory();

  return useCallback(
    (profile: Profile) => {
      if (!currentProfileUuid) {
        setCurrentProfile(profile);
      }

      history.push({
        pathname: getProfileDetailsRoute(profile),
        state: {
          activeTab: ProfileDetailsTabKeys.PARENTAL_CONTROL,
        },
      });
    },
    [history, currentProfileUuid, setCurrentProfile]
  );
};

export const checkIsKidsProfile = (profile?: Profile) =>
  profile ? profile.maturityRating === MATURITY_KIDS_VALUE : false;

export const getContentRatingOptions: () => DropdownOption[] = () => [
  {
    label: t('profile.allMaturityRatings'),
    subLabel: t('profile.allMaturityRatingsMessage') as string,
    value: ProfileContentRating.ALL,
  },
  {
    label: t('profile.kidsFriendly'),
    subLabel: t('profile.kidsFriendlyMessage') as string,
    value: ProfileContentRating.KIDS,
    icon: <BearIcon />,
  },
];
