import { ReactComponent as Stars } from 'assets/icons/stars.svg';
import { ReactComponent as Like } from 'assets/icons/interestLike.svg';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAvailableInterests } from 'store/app/selectors';
import { Button } from '@laminar-product/client-commons-core/web';
import { createPortal } from 'react-dom';
import { updateProfile } from 'actions/profiles';
import { selectCurrentProfile } from 'store/user/selectors';
import { useUpdateProfilesState } from 'utils/profiles';
import {
  NO_PREFERENCE,
  Profile,
} from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

const InterestsModal = ({
  setOpen,
  type = 'edit',
}: {
  setOpen: (open: boolean) => void;
  type?: 'register' | 'edit';
}) => {
  const { t } = useTranslation();
  const currentProfile = useSelector(selectCurrentProfile);
  const [selectedInterests, setSelectedInterests] = useState<string[]>(
    currentProfile?.interests || [NO_PREFERENCE]
  );
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const updateProfilesInState = useUpdateProfilesState();
  const interests = useSelector(selectAvailableInterests);

  const onSelectInterest = useCallback(
    ({ interest, isSelected }: { interest: string; isSelected: boolean }) => {
      if (
        selectedInterests.includes(NO_PREFERENCE) &&
        interest !== NO_PREFERENCE
      ) {
        setSelectedInterests([]);
      }
      setSelectedInterests((prev) =>
        isSelected
          ? prev.filter((item) => item !== interest)
          : [...prev, interest]
      );
    },
    [selectedInterests]
  );

  const onNoPreferenceClick = useCallback(
    () => setSelectedInterests([NO_PREFERENCE]),
    []
  );

  const updateProfileInterests = useCallback(async () => {
    try {
      setUpdatingProfile(true);
      const updatedProfile = {
        ...currentProfile,
        interests: selectedInterests,
      };
      await updateProfile(updatedProfile as Profile);
      updateProfilesInState(updatedProfile as Profile);
      setUpdatingProfile(false);
      setOpen(false);
    } catch (error) {
      setUpdatingProfile(false);
    }
  }, [currentProfile, selectedInterests, setOpen, updateProfilesInState]);

  return createPortal(
    <section className={styles.wrapper}>
      <span className={styles.iconWrapper}>
        <Stars />
      </span>
      <h2 className={styles.header}>{t('interests.modalTitle')}</h2>
      <p className={styles.subHeader}>{t('interests.modalSubtitle')}</p>

      <article className={styles.interestsWrapper}>
        <h4 className={styles.interestsHeader}>
          {t('interests.selectInterests')}
        </h4>
        <ul className={styles.listOfInterests}>
          {interests?.map((interest, index) => {
            const isSelected = selectedInterests.includes(interest);
            return (
              <li
                key={index}
                onClick={() => onSelectInterest({ interest, isSelected })}
                className={cn(styles.interest, {
                  [styles.selected]: isSelected,
                })}
              >
                <span className={styles.backgroundColor} />
                <span className={styles.interestName}>{interest}</span>
                {isSelected && (
                  <span className={styles.chosenIcon}>
                    <Like />
                  </span>
                )}
              </li>
            );
          })}
          <Button
            variant="secondary"
            onClick={onNoPreferenceClick}
            className={cn(styles.noPreferenceBtn, {
              [styles.selected]: selectedInterests.includes(NO_PREFERENCE),
            })}
          >
            <span className={styles.interestName}>
              {t('interests.noPreference')}
            </span>
            {selectedInterests.includes(NO_PREFERENCE) && (
              <span className={styles.chosenIcon}>
                <Like />
              </span>
            )}
          </Button>
        </ul>
        {type === 'register' && (
          <Button
            className={styles.continueButton}
            variant="cta"
            disabled={!selectedInterests.length}
            onClick={updateProfileInterests}
            loading={updatingProfile}
          >
            {t('common.continue')}
          </Button>
        )}
        {type === 'edit' && (
          <article className={styles.buttonWrapper}>
            <Button
              className={styles.cancelButton}
              variant="secondary"
              disabled={!selectedInterests.length}
              onClick={() => setOpen(false)}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className={styles.saveButton}
              variant="cta"
              disabled={!selectedInterests.length}
              onClick={updateProfileInterests}
              loading={updatingProfile}
            >
              {t('common.save')}
            </Button>
          </article>
        )}
      </article>
    </section>,
    document.body
  );
};
export default InterestsModal;
