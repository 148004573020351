import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initializeApp } from 'firebase/app';
import { History } from 'history';
import { newTracker } from '@snowplow/browser-tracker';
import { getAuth } from 'firebase/auth';
import merge from 'lodash/merge';
import { getReleaseVersion } from 'utils/helpers';
import {
  AppCheck,
  ReCaptchaV3Provider,
  initializeAppCheck,
} from 'firebase/app-check';

const initMonitoring = (history: History) => {
  if (import.meta.env.VITE_ENVIRONMENT === 'development') {
    console.warn('Sentry is not loaded in development build.');
    return;
  }

  Sentry.init({
    sampleRate: 0.6,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT || 'development',
    tracesSampleRate: 0,
    release: 'web-client@' + getReleaseVersion(),
    beforeBreadcrumb: (breadcrumb) =>
      breadcrumb.category === 'ui.click' ? null : breadcrumb,
    beforeSend: (event) => {
      const currentUser = getAuth().currentUser;
      const customExtra = {
        // Note: Use 'firebase.', not camelCase to meet Sentry convention
        'firebase.isAnonymous': currentUser?.isAnonymous,
        'firebase.userUuid': currentUser?.uid,
      };
      const newEvent = merge(event, { tags: customExtra });
      return newEvent;
    },
  });
};

const enableMocks = async () => {
  if (import.meta.env.VITE_ENABLE_MOCKING === 'true') {
    const worker = require('./mocks/index.js');
    await worker.default.start();
  }
};

export let appCheck: AppCheck | undefined;
const initFirebase = () => {
  try {
    const credentials = import.meta.env.VITE_FIREBASE_CREDENTIALS;
    if (!credentials) throw new Error();
    const firebaseCredentials = JSON.parse(credentials);
    const app = initializeApp(firebaseCredentials);

    if (import.meta.env.VITE_RECAPTCHA_SITE_KEY) {
      appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
          import.meta.env.VITE_RECAPTCHA_SITE_KEY
        ),

        isTokenAutoRefreshEnabled: true,
      });
    }
  } catch (e) {
    console.error('Missing or invalid firebase credentials');
    Sentry.captureMessage('Missing or invalid firebase credentials');
  }
};

const setupApp = async (history: History) => {
  const isDev = import.meta.env.NODE_ENV === 'development';

  if (isDev) {
    await enableMocks();
    console.warn('Sentry is not loaded in development build.');
  } else {
    initMonitoring(history);
  }
  initFirebase();

  newTracker('tracker', import.meta.env.VITE_ANALYTICS || '', {
    appId: 'browser',
    platform: 'web',
  });
};

export default setupApp;
