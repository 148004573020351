import SettingsSwitchItem from 'components/SettingsSwitchItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CodeInput from 'react-code-input';
import { Profile } from '@laminar-product/client-commons-core/core';
import ProfileIdentityConfirmation from 'pages/ProfilesSelector/ForgotPin/ProfileIdentityConfirmation';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { selectIsTimesSSOProvider } from 'store/user/selectors';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';

interface PINControlProps {
  profile?: Profile;
  saveProfile: (profile: Profile) => void;
}

const ParentalPINControl = ({ profile, saveProfile }: PINControlProps) => {
  const { t } = useTranslation();
  const [pinControlChecked, setPinControlChecked] = useState(false);
  const [pinValue, setPinValue] = useState<string>();
  const [isPinValid, setIsPinValid] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const isTimesSSOProvider = useSelector(selectIsTimesSSOProvider);

  const handlePinToogle = (checked: boolean) => {
    setPinControlChecked(checked);

    if (!checked && profile?.pin) {
      setPinValue('');
      saveProfile({ uuid: profile.uuid, pin: '' });
    }
  };

  const validatePin = (value: string) => {
    setPinValue(value);

    if (value && value.length === 4 && value !== profile?.pin) {
      setIsPinValid(true);
    } else {
      setIsPinValid(false);
    }
  };

  const savePin = () => {
    if (!profile?.uuid) return;

    saveProfile({ uuid: profile.uuid, pin: pinValue });
    setIsPinValid(false);
  };

  const onSavePinButtonClick = () => {
    //If no pin before - need to be verified. If timesSSO provider - no need to verify for now (https://laminarglobal.atlassian.net/browse/DDD-7691)
    if (!profile?.pin && !isTimesSSOProvider) {
      setIsConfirmationModalOpen(true);
      return;
    }

    savePin();
  };

  const onConfirmationSuccess = () => {
    setIsConfirmationModalOpen(false);
    savePin();
  };

  useEffect(() => {
    if (profile && profile.pin?.length === 4) {
      setPinControlChecked(true);
      setPinValue(profile.pin);
    }
  }, [profile]);

  return (
    <>
      <div className={styles.wrapper}>
        <SettingsSwitchItem
          noBorder
          checked={pinControlChecked}
          handleToggle={handlePinToogle}
          title={t(`parentalControlSettings.parentalControlPIN`)}
          subTitle={t(`parentalControlSettings.parentalControlPINDesctipion`)}
          theme="settings"
        />

        {pinControlChecked && (
          <div className={styles.pinInput}>
            <CodeInput
              autoFocus={false}
              value={pinValue}
              onChange={validatePin}
              inputMode="latin"
              name="oneTimeCode"
              type="number"
              fields={4}
              className={styles.codeInput}
            />

            <Button
              disabled={!isPinValid}
              className={styles.saveBtn}
              variant="primary-settings"
              onClick={onSavePinButtonClick}
            >
              {t('common.save')}
            </Button>
          </div>
        )}
      </div>

      <Modal
        wrapperId="profileConfirmationModal"
        isOpen={!!profile && isConfirmationModalOpen}
      >
        <ProfileIdentityConfirmation
          profile={profile!}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirmationSuccess={onConfirmationSuccess}
        />
      </Modal>
    </>
  );
};

export default ParentalPINControl;
