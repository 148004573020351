import { useCallback, useEffect, useRef } from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui'; //Temporary: Incorrect export from shaka-package - https://gist.github.com/Security2431/2b28f17e11870bb4b0e347673e16d5ba
import 'shaka-player/dist/controls.css';
import cn from 'classnames';
import { selectPlayerPlaybackId } from 'store/player/selectors';
import { useSelector } from 'react-redux';
import { isMobileOrTablet } from 'utils/isMobileOrTablet';
import { requestFullScreen } from 'utils/player';
import { isIos } from 'utils/getUserOs';
import { VideoPlayerBasicProps } from '../types';
import styles from './index.module.scss';

const shakaEventManager = new shaka.util.EventManager();

interface ShakaPlayerEvent extends Event {
  buffering?: boolean;
}

const VideoPlayerComponent = ({
  loadConfig,
  loop,
  playerId,
  className,
  uiConfig,
  onPlayerInitialized,
  setPlayerState,
  muted = false,
  onVideoReadyToPlay,
  onVideoPause,
  onVideoPlay,
  onVideoSeek,
  onPlayerError,
  onVideoBufferStart,
  onVideoBufferEnd,
  Frames,
  Overlay,
}: VideoPlayerBasicProps) => {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<shaka.Player | null>(null);
  const eventManager = useRef<shaka.util.EventManager | null>(
    shakaEventManager
  );
  const uiRef = useRef<shaka.ui.Overlay | null>(null);
  const videoMediaElement = playerRef.current?.getMediaElement();
  const playbackId = useSelector(selectPlayerPlaybackId);

  const loadPlaybackSource = useCallback(async () => {
    try {
      if (
        !playerRef.current ||
        !videoContainerRef.current ||
        !videoElementRef.current
      )
        return;

      const { sourceUrl, startTime } = loadConfig;

      onVideoReadyToPlay?.();
      await playerRef.current.load(sourceUrl, startTime);
      if (isMobileOrTablet()) {
        requestFullScreen(
          isIos() ? videoElementRef.current : videoContainerRef.current
        );
      }
      onPlayerInitialized?.(playerRef.current);
    } catch (e) {
      onPlayerError?.(e as shaka.extern.Error);
    }
  }, [loadConfig, onPlayerError, onPlayerInitialized, onVideoReadyToPlay]);

  const initPlayer = useCallback(() => {
    if (!videoElementRef.current || !videoContainerRef.current) {
      return;
    }

    shaka.polyfill.installAll();
    shaka.polyfill.PatchedMediaKeysApple.install();

    try {
      if (!shaka.Player.isBrowserSupported()) {
        throw new Error('Browser not supported by shaka player');
      }
      // When player is not unmounting we change source and keep previous reference; Happens e.g when changing between pages with jumbotron
      const player =
        playerRef.current || new shaka.Player(videoElementRef.current);

      player.setVideoContainer(videoContainerRef.current);

      const { options } = loadConfig;

      player.configure(options);
      playerRef.current = player;
      //Check to not render multiple UI controls when changing episodes
      if (uiConfig) {
        const ui =
          uiRef.current ||
          new shaka.ui.Overlay(
            playerRef.current,
            videoContainerRef.current,
            videoElementRef.current
          );

        ui.configure(uiConfig);
        uiRef.current = ui;
      }

      setPlayerState?.();
    } catch (e) {
      onPlayerError?.(e as shaka.extern.Error);
    }
  }, [loadConfig, onPlayerError, setPlayerState, uiConfig]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
        uiRef.current?.destroy();
        uiRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    initPlayer();
  }, [initPlayer]);

  useEffect(() => {
    //For jumbotron, asset details and posters playbackId is not generated
    if (!playbackId && loadConfig.variant === 'primary') {
      return;
    }

    loadPlaybackSource();
  }, [playbackId, loadPlaybackSource, loadConfig.variant]);

  useEffect(() => {
    if (!videoMediaElement || !onVideoPause) {
      return;
    }

    videoMediaElement.addEventListener('pause', onVideoPause);

    return () => videoMediaElement.removeEventListener('pause', onVideoPause);
  }, [onVideoPause, videoMediaElement]);

  useEffect(() => {
    if (!videoMediaElement || !onVideoPlay) {
      return;
    }

    videoMediaElement.addEventListener('play', onVideoPlay);

    return () => videoMediaElement.removeEventListener('play', onVideoPlay);
  }, [onVideoPlay, videoMediaElement]);

  useEffect(() => {
    if (!videoMediaElement || !onPlayerError) {
      return;
    }

    const errorHandler = () => {
      const error = videoMediaElement.error;

      if (!error) {
        return;
      }

      onPlayerError(error);
    };

    videoMediaElement.addEventListener('error', errorHandler);

    return () => videoMediaElement.removeEventListener('error', errorHandler);
  }, [onPlayerError, videoMediaElement]);

  useEffect(() => {
    if (!videoMediaElement || !onVideoBufferStart || !onVideoBufferEnd) {
      return;
    }

    const shakaEventRef = eventManager.current;

    const onBuffering = (e: ShakaPlayerEvent) =>
      e.buffering ? onVideoBufferStart() : onVideoBufferEnd();

    shakaEventRef?.listen(playerRef.current, 'buffering', onBuffering);

    return () => {
      shakaEventRef?.unlisten(playerRef.current, 'buffering', onBuffering);
    };
  }, [onVideoBufferEnd, onVideoBufferStart, videoMediaElement]);

  //Handlers for seek timeline & seek buttons
  useEffect(() => {
    if (!videoMediaElement || !onVideoSeek) {
      return;
    }

    let seekStartTime = 0;

    //Need to keep delay so we know the previous time before seek finishes
    const updateSeekStartTime = () => {
      setTimeout(() => {
        seekStartTime = videoMediaElement.currentTime;
      }, 500);
    };

    const onSeek = () => {
      const direction =
        seekStartTime <= videoMediaElement.currentTime ? 'forward' : 'backward';

      onVideoSeek(direction);
    };

    videoMediaElement.addEventListener('timeupdate', updateSeekStartTime);
    videoMediaElement.addEventListener('seeking', onSeek);

    return () => {
      videoMediaElement.removeEventListener('timeupdate', updateSeekStartTime);
      videoMediaElement.removeEventListener('seeking', onSeek);
    };
  }, [onVideoSeek, videoMediaElement]);

  return (
    <div
      ref={videoContainerRef}
      className={cn(styles.videoContainer, className)}
      data-testid="VideoPlayerComponent__Container"
    >
      <video
        id={playerId}
        ref={videoElementRef}
        className={styles.video}
        loop={loop}
        data-testid="VideoPlayerComponent__Video"
        autoPlay
        muted={muted}
      />

      {/* frames & overlay needs to be rendered inside video container to be displayed in full screen mode */}
      {Overlay}
      {Frames}
    </div>
  );
};

export default VideoPlayerComponent;
