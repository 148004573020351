import { Button, Modal, Tabs } from '@laminar-product/client-commons-core/web';
import {
  useFetchActionObj,
  useWindowSize,
} from '@laminar-product/client-commons-core/hooks';
import { getProfileById, updateProfile } from 'actions/profiles';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ProfileDetailsTabKeys, ProfileItemType } from 'types/profiles';
import { RouteIdParams } from 'utils/props';
import { Tab } from 'components/Tabs';
import { useTranslation } from 'react-i18next';
import { SettingsTabKey } from 'types/settings';
import { settingsPath } from 'utils/constants';
import {
  useGetCurrentProfile,
  useSetCurrentProfile,
  useUpdateProfilesState,
} from 'utils/profiles';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { Profile } from '@laminar-product/client-commons-core/core';
import { captureError } from 'utils/captureError';
import { Severity } from 'types/errors';
import { AxiosError } from 'axios';
import GoBackLink from 'components/GoBackLink';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import ProfileListItem from '../ProfileListItem';
import styles from './index.module.scss';
import PlaybackTab from './PlaybackTab';
import ParentalControlTab from './ParentalControlTab';
import ProfileRemove from './ProfileRemove';
import DetailsTab from './DetailsTab';

interface ProfileDetailsLocationProps {
  activeTab: ProfileDetailsTabKeys;
}

const ProfileDetails = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParams>();
  const updateProfilesInState = useUpdateProfilesState();
  const setCurrentProfile = useSetCurrentProfile();
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const {
    data: profile,
    isLoading: isFetchingProfile,
    refresh,
  } = useFetchActionObj(useCallback(() => getProfileById(id), [id]));

  const { isTabletWindowSize } = useWindowSize();
  const currentProfile = useGetCurrentProfile();
  const location = useLocation<ProfileDetailsLocationProps>();
  const activeProfileDetailsKey: ProfileDetailsTabKeys =
    location?.state?.activeTab || ProfileDetailsTabKeys.PROFILE_DETAILS;

  const saveProfile = (profileToSave: Profile) => {
    updateProfile(profileToSave)
      .then(() => {
        const profileToUpdate = {
          ...profile,
          ...profileToSave,
        };
        updateProfilesInState(profileToUpdate);

        if (profileToSave.uuid === currentProfile?.uuid) {
          setCurrentProfile(profileToUpdate);
        }

        refresh();
      })
      .catch((e) => {
        captureError(
          e as AxiosError,
          'ProfileDetails/saveProfile',
          Severity.Warning,
          { extra: { profileToSave } }
        );
      });
  };

  const returnToProfiles = () => {
    push({
      pathname: settingsPath,
      state: {
        activeMenu: SettingsTabKey.PROFILES,
      },
    });
  };

  const renderDeleteButton = () =>
    isTabletWindowSize ? (
      <div className={styles.trashIconWrapper}>
        <Trash
          className={styles.trashIcon}
          onClick={() => setRemoveModalVisible(true)}
        />
      </div>
    ) : (
      <Button
        variant="secondary-settings"
        onClick={() => setRemoveModalVisible(true)}
      >
        {t('profile.deleteProfile')}
      </Button>
    );

  return (
    <div className={styles.root}>
      <GoBackLink text="profile.goBackToProfiles" onClick={returnToProfiles} />
      {profile ? (
        <>
          <div className={styles.detailsHeader}>
            <ProfileListItem profile={profile} type={ProfileItemType.HEADER} />

            {profile && !profile?.defaultProfile ? renderDeleteButton() : null}
          </div>
          <div>
            <Tabs
              defaultActiveKey={activeProfileDetailsKey}
              navActiveClassName={styles.tabsActiveNavigation}
              navClassName={styles.tabsNavigation}
              settingsTab
            >
              <Tab
                key={ProfileDetailsTabKeys.PROFILE_DETAILS}
                label={t('profile.profileDetails')}
              >
                <DetailsTab
                  isLoading={isFetchingProfile}
                  profile={profile}
                  saveProfile={saveProfile}
                />
              </Tab>
              <Tab
                key={ProfileDetailsTabKeys.PLAYBACK}
                label={t('profile.playback')}
              >
                <PlaybackTab
                  isLoading={isFetchingProfile}
                  profile={profile}
                  saveProfile={saveProfile}
                />
              </Tab>
              <Tab
                key={ProfileDetailsTabKeys.PARENTAL_CONTROL}
                label={t('profile.parentalControl')}
              >
                <ParentalControlTab
                  profile={profile}
                  saveProfile={saveProfile}
                />
              </Tab>
            </Tabs>
          </div>

          <Modal wrapperId="profileRemoveModal" isOpen={isRemoveModalVisible}>
            <ProfileRemove
              profile={profile}
              onClose={() => setRemoveModalVisible(false)}
            />
          </Modal>
        </>
      ) : (
        <AppLoadingIndicator className={styles.loader} />
      )}
    </div>
  );
};

export default ProfileDetails;
