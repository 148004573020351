//https://shaka-player-demo.appspot.com/docs/api/tutorial-ui-customization.html
import { useMemo } from 'react';
import 'library/VideoPlayer/custom/index';
import { isMobileOrTablet } from './isMobileOrTablet';

export interface VideoPlayerUIConfigOptions {
  fadeDelay: number;
  overflowMenuButtons: string[];
  controlPanelElements: string[];
}

const baseUiConfig: VideoPlayerUIConfigOptions = {
  fadeDelay: 1.5,
  overflowMenuButtons: ['quality', 'language', 'captions'],
  controlPanelElements: [],
};

const volumeElements = isMobileOrTablet() ? [] : ['mute', 'volume'];

const useVideoPlayerUI = () => {
  const uiConfig: VideoPlayerUIConfigOptions = useMemo(
    () => ({
      ...baseUiConfig,
      controlPanelElements: [
        'time_and_duration',
        'video_player_skip_left_button',
        'play_pause',
        'video_player_skip_right_button',
        ...volumeElements,
        'fullscreen',
        'overflow_menu',
      ],
    }),
    []
  );

  return uiConfig;
};

export const livePlayerUiConfig: VideoPlayerUIConfigOptions = {
  ...baseUiConfig,
  controlPanelElements: [
    'video_player_live_indicator',
    'play_pause',
    ...volumeElements,
    'fullscreen',
    'overflow_menu',
  ],
};

export default useVideoPlayerUI;
