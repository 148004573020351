import {
  Resolution,
  getHighestAvailableResolutionOnPlan,
} from '@laminar-product/client-commons-core/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPlanQuality } from 'store/user/selectors';

//TODO: MOVE TO COMMONS
export const getResolutionWidth = (resolution: Resolution): number => {
  switch (resolution) {
    case Resolution.SD270:
      return 480;
    case Resolution.SD360:
      return 640;
    case Resolution.SD540:
      return 960;
    case Resolution.HD:
      return 1280;
    case Resolution.FHD:
      return 1920;
    case Resolution.UHD:
      return 3840;
    default:
      return 1920;
  }
};

const useVideoPlayerQuality = () => {
  const qualities = useSelector(selectUserPlanQuality);

  const quality = useMemo(() => {
    const resolution = getHighestAvailableResolutionOnPlan(qualities);

    return {
      maxHeight: resolution,
      maxWidth: getResolutionWidth(resolution),
    };
  }, [qualities]);

  return quality;
};

export default useVideoPlayerQuality;
